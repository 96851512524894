


























































import {Component, Mixins, Prop} from 'vue-property-decorator'
import GetStoreMixin from "@mixins/GetStore";
import {IWorkStruct} from "@store/works/Interface";
import {worksNamespace} from '@store/works'
import {WorksActionTypes} from '@store/works/Types'

@Component({
  name: 'WorkListAddEditWorkStructForm',
})

export default class WorkListAddEditWorkStructForm extends Mixins(GetStoreMixin) {
  @worksNamespace.State('workStructs')
  private workStructsList!: IWorkStruct[]

  @worksNamespace.Action(WorksActionTypes.A_ADD_WORK_STRUCT)
  private addWorkStruct!: (payload: IWorkStruct) => Promise<void>

  @worksNamespace.Action(WorksActionTypes.A_UPDATE_WORK_STRUCT)
  private updateWorkStruct!: (payload: IWorkStruct) => Promise<void>

  @worksNamespace.Action(WorksActionTypes.A_DELETE_WORK_STRUCT)
  private deleteWorkStruct!: (id: number) => Promise<{ message: string }>

  @worksNamespace.Action(WorksActionTypes.A_CAN_DELETE_WORK_STRUCT)
  private canDeleteWorkStruct!: (id: number) => Promise<boolean>

  @Prop(Object) readonly WorkStruct!: IWorkStruct

  private canDelete: boolean = false

  private formWorkStruct: IWorkStruct | null = null
  protected rules = {
    name: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
  }
  private loading: boolean = false

  get isEdit() {
    return !!this.formWorkStruct?.id
  }

  private saveModal() {
    let successMessage = "Добавление успешно выполнено"
    let action = this.addWorkStruct

    if (this.isEdit) {
      successMessage = "Обновление успешно выполнено"
      action = this.updateWorkStruct
    }

    if (!this.formWorkStruct) return

    this.loading = true
    this.formWorkStruct.projectId = this.selectedProject

    action(this.formWorkStruct)
      .then(() => {
        this.$notify({title: 'Выполнено', message: successMessage, type: 'success'})
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error?.response?.data?.message || 'На сервере произошла ошибка'
        })
      })
      .finally(() => {
        this.loading = false
        this.closeModal()
      })
  }

  private deleteWorkStructHandler() {
    if (this.formWorkStruct?.id) {
      this.loading = true

      this.$confirm('Вы действительно хотите удалить структуру?', 'Внимание!', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning',
      }).then(() => {
        if(!this.formWorkStruct) return

        this.deleteWorkStruct(this.formWorkStruct.id).then(({message}) => {
          this.$notify({title: 'Выполнено', message, type: 'success'})
        }).catch(error => {
          this.$notify.error({
            title: 'Ошибка',
            message: error?.response?.data?.message || 'На сервере произошла ошибка'
          })
        }).finally(() => {
          this.loading = false
          this.closeModal()
        })
      })
    }
  }

  closeModal() {
    this.$emit('close')
  }

  created() {
    this.formWorkStruct = {...this.WorkStruct}
    this.canDeleteWorkStruct(this.formWorkStruct.id).then((res) => {
      this.canDelete = res
    })
  }
}
